<!--
 * @Editors: pan long
 -->
<template>
  <div style="overflow: hidden;">
    <Header title="linyuan"></Header>
    <BannerTitle title="linyuan"></BannerTitle>
    <div class="lyBox">
      <div class="introduce wow slideInUp">
        <p>{{ $t("ly.summary") }}</p>
      </div>
      <div class="characteristic wow slideInUp">
        <div>
          <img src="@/assets/images/linyuan1.png" alt="" />
          <span>{{ $t("ly.tips1") }}</span>
        </div>
        <div>
          <img src="@/assets/images/linyuan2.png" alt="" />
          <span>{{ $t("ly.tips2") }}</span>
        </div>
        <div>
          <img src="@/assets/images/linyuan3.png" alt="" />
          <span>{{ $t("ly.tips3") }}</span>
        </div>
      </div>
      <img
        src="@/assets/images/linyuan4.png"
        alt=""
        class="procedure wow slideInUp"
        v-show="$i18n.locale == 'zh'"
      />
      <img
        src="@/assets/images/linyuan4en.jpg"
        alt=""
        class="procedure wow slideInUp"
        v-show="$i18n.locale == 'en'"
      />
      <Start class="wow slideInUp"></Start>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
import Start from "../../components/stars.vue";
export default {
  name: "linyuan",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline,
    Start
  }
};
</script>

<style lang="scss">
.lyBox {
  width: 1300px;
  margin: auto;
  .introduce {
    width: 1000px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 40px;
    margin: auto;
    text-align: center;
  }
  .characteristic {
    width: 1000px;
    margin: 80px auto 0;
    display: flex;
    justify-content: space-around;
    img {
      width: 72px;
      height: 72px;
      display: block;
      margin: auto;
    }
    span {
      display: block;
      margin-top: 18px;
      height: 33px;
      font-size: 24px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
    }
  }
  .procedure {
    display: block;
    width: 900px;
    margin: 68px auto 0;
  }
}
</style>
